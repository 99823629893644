import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotificationModule } from "@progress/kendo-angular-notification";
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { Globals } from './shared/globals';

declare var $: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, NotificationModule, HeaderComponent, FooterComponent, LoaderComponent],
  templateUrl: './app.component.html',
  providers: []
})
export class AppComponent {

  constructor(protected globals: Globals) { }

  title = 'Stateside-Notification-Frontend';

  isReleaseNotes = false;

  ngOnInit(): void {
    if (!this.globals.isProduction) {
      console.log(this.globals.version);
    }
    if (typeof document !== 'undefined') {
      const body: any = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() + "px");
    }
    if (window.location.pathname === '/release-notes') {
      this.isReleaseNotes = true;
    }
  }
}
