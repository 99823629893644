<header
  [ngClass]="{
    header_impersonate: globals.impersonateUserInfo,
    admin_header: globals.authData?.role_id == 1,
    client_header: globals.authData?.role_id != 1
  }"
>
  <div *ngIf="globals.impersonateUserInfo" class="header_impersonate_block">
    <p>
      You are impersonating "<b>{{ globals.impersonateUserInfo.name }}</b
      >"
    </p>
    <button (click)="stopImpersonate()">
      <i class="fa-solid fa-stop"></i> Stop
    </button>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 col-xxl-1 d-none d-xl-block"
      >
        <div class="logo_block">
          <a routerLink="/" target="_blank" class="logo_a"
            ><img src="assets/images/sl-logo.png" alt="" class="img-fluid"
          /></a>
        </div>
      </div>
      <div
        class="col-7 col-sm-6 col-md-8 col-lg-10 col-xl-10 col-xxl-10 admin_menu"
      >
        <!--add class="client_menu"-->
        <div class="menu_block navbar navbar-expand-xl">
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars"></i>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <ng-container *ngFor="let link of globals.navLinks">
                <li
                  class="nav-item"
                  [ngClass]="{ active: isCurrentRoute(link, 1) }"
                >
                  <a
                    *ngIf="!link.isExternalRoute && !link.disableRedirection"
                    [routerLink]="link.route"
                    >{{ link.label }}</a
                  >
                  <a *ngIf="!link.isExternalRoute && link.disableRedirection">{{
                    link.label
                  }}</a>
                  <a *ngIf="link.isExternalRoute" [href]="link.route">{{
                    link.label
                  }}</a>
                </li>
                <li *ngIf="link.hasDivider" class="divider"><span></span></li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="logo_block d-lg-block d-xl-none">
          <a routerLink="/" target="_blank" class="logo_a"
            ><img src="assets/images/sl-logo.png" alt="" class="img-fluid"
          /></a>
        </div>
      </div>
      <div class="col-5 col-sm-6 col-md-4 col-lg-2 col-xl-1 col-xxl-1">
        <div class="dropdown user_dropdown">
          <button
            class="user_button dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!-- <img
              src="assets/images/user-profile.png"
              class="img-fluid"
              alt=""
            /> -->
            <span class="name">{{ globals.authData?.name }}</span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <div class="email_block">
                {{ globals.authData?.name
                }}<span>{{ globals.authData?.email }}</span>
              </div>
            </li>
            <!-- <li>
              <a><i class="fa-regular fa-user"></i>Profile</a>
            </li> -->
            <li>
              <button (click)="logout()">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
<div *ngIf="showImpersonateHeader" class="impersonate_header">
  <!--This is impersonate menu-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-end">
        <div class="drawer_block">
          <button (click)="impersonateDialog()">Impersonate</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Detail Popup-->
<kendo-dialog
  *ngIf="impersonateDialogOpened"
  (close)="impersonateCloseDialog()"
  [width]="600"
  class="impersonate_block"
>
  <kendo-dialog-titlebar>
    <h3 class="mb-0">Whom do you want to impersonate?</h3>
  </kendo-dialog-titlebar>
  <div>
    <div class="col mb-3">
      <label><span class="red">*</span> Email Address</label>
      <kendo-textbox
        [(ngModel)]="impersonateUserEmail"
        required
        name="Email Address"
        [showSuccessIcon]="isValidEmail()"
        showErrorIcon="initial"
        [disabled]="impersonateUserDetails?.id"
      >
      </kendo-textbox>
      <app-form-error [errorMsg]="impersonateUserError"></app-form-error>
    </div>
    <div *ngIf="impersonateUserDetails?.id" class="search_warning">
      <div class="alert alert-warning d-flex align-items-center" role="alert">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <div>Here are the details of the email address you entered.</div>
      </div>
      <div class="content">
        <p class="mb-2"><b>Name:</b> {{ impersonateUserDetails.name }}</p>
        <p class="mb-2">
          <b>Client:</b> {{ impersonateUserDetails.client_name }}
        </p>
        <p class="mb-2">
          <b>Address: </b>
          <ng-container *ngIf="hasAddress()">
            {{
              impersonateUserDetails.address_line_1
                ? impersonateUserDetails.address_line_1 + ","
                : ""
            }}
            {{
              impersonateUserDetails.address_line_2
                ? impersonateUserDetails.address_line_2 + ","
                : ""
            }}
            {{
              impersonateUserDetails.city
                ? impersonateUserDetails.city + ","
                : ""
            }}
            {{ impersonateUserDetails.state }}
            {{ impersonateUserDetails.zip }}
          </ng-container>
          <ng-container *ngIf="!hasAddress()">NA</ng-container>
        </p>
      </div>
    </div>
  </div>
  <kendo-dialog-actions *ngIf="!impersonateUserDetails" class="sure_block">
    <button
      (click)="getUserDetailsForImpersonat()"
      [disabled]="!isValidEmail()"
      kendoButton
      class="all_btn theme_btn"
    >
      Search
    </button>
  </kendo-dialog-actions>
  <kendo-dialog-actions *ngIf="impersonateUserDetails?.id" class="sure_block">
    <p class="mt-2 me-2 mb-0">
      Are you sure you want to impersonate this person?
    </p>
    <button (click)="startImpersonate()" kendoButton class="all_btn theme_btn">
      Yes
    </button>
    <button
      (click)="clearImpersonateDetail()"
      kendoButton
      class="all_btn themeoption_btn"
    >
      No
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
