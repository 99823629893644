<ng-container *ngIf="isReleaseNotes">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!isReleaseNotes">
  <app-header *ngIf="globals.isLoggedIn"></app-header>
  <div class="main_wrapper">
    <div
      class="loader"
      [ngStyle]="{
        top: globals.isLoggedIn ? '125px' : '0'
      }"
      *ngIf="globals.isLoading()"
    >
      <div class="loader-circle"></div>
      <span class="loader-text">Loading...</span>
    </div>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
</ng-container>
